<template>
  <div>
    <van-nav-bar
      title="积分明细"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <el-table :data="scoreData" stripe style="width: 100%">
      <el-table-column label="序号" type="index" min-width="10%" align="center"></el-table-column>
      <el-table-column prop="score" label="积分" min-width="15%" align="center">
        <template slot-scope="scope">
          <span>+{{ scope.row.score }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="scoreType" label="类型" min-width="35%">
        <template slot-scope="scope">
          <span v-if="scope.row.scoreType === 1">日常登录</span>
					<span v-else-if="scope.row.scoreType === 2">基础资料填写</span>
					<span v-else-if="scope.row.scoreType === 3 || scope.row.scoreType === 6">健康数据录入</span>
          <span v-else-if="scope.row.scoreType === 4">笔帽绑定</span>
          <span v-else-if="scope.row.scoreType === 5">健康数据导入</span>
          <span v-else-if="scope.row.scoreType === 7">CGM设备绑定</span>
          <span v-else-if="scope.row.scoreType === 8">胰岛素泵设备绑定</span>
          <span v-else>其他</span>
        </template>
      </el-table-column>
      <el-table-column prop="scoreTime" label="日期" min-width="38%">
        <template slot-scope="scope">
          <span>{{ scope.row.scoreTime }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<style scoped>
.van-nav-bar {
  background: #0F276D; 
  position: sticky;
  top: 0; 
  z-index: 99;
}
/deep/ .van-nav-bar__title {
  color: white !important;
  font-size: 18px;
}
/deep/ .van-nav-bar .van-icon {
  color: white !important;
}
/deep/ .van-nav-bar__content {
  height: 45px;
}
</style>
<script>
import Vue from 'vue';
import { NavBar } from 'vant';
import 'vant/lib/index.css';

Vue.use(NavBar);

export default {
  name: "UserScoreDetail",
  data() {
    return {
      loading: true,
      userUuid: "",
      scoreData: []
    }
  },
  mounted() {
    this.userUuid = this.$route.query.userUuid;
    this.getUserScores();
  },
  methods: {
    getUserScores() {
      if(this.userUuid != null && this.userUuid != "") {
        this.$axios({
						method: "post",
						url: "/gluApp/glu/app/glu/goal/userScoreList", // 接口地址
						data: {
              userUuid: this.userUuid
            }
					}).then(res => {
            this.loading = false;
            if(res.data.code === 200) {
              this.scoreData = res.data.data;
            }
          });
      }
    },
    onClickLeft() {
      this.$router.go(-1);
    }
  }
}
</script>